var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "config-index-component table-component" }, [
    _c("div", { staticClass: "page-header" }, [
      _c(
        "div",
        { staticClass: "page-breadcrumb" },
        [
          _c(
            "a-breadcrumb",
            [
              _c(
                "a-breadcrumb-item",
                [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                1
              ),
              _c("a-breadcrumb-item", [_vm._v("图片配置")])
            ],
            1
          )
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "page-body page-body-margin" },
      [
        _c(
          "a-layout",
          [
            _c(
              "a-layout-sider",
              { staticClass: "page-menu-sider", attrs: { theme: "light" } },
              [
                _c(
                  "a-card",
                  { attrs: { title: "图片配置", bordered: false } },
                  [
                    _c(
                      "a-menu",
                      {
                        attrs: {
                          "selected-keys": [_vm.SelectedKeys],
                          theme: "light"
                        }
                      },
                      [
                        _c(
                          "a-menu-item",
                          { key: "web" },
                          [
                            _c(
                              "router-link",
                              { attrs: { to: "/dashboard/config-image/web" } },
                              [_vm._v("Web端")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "a-menu-item",
                          { key: "mobile" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: { to: "/dashboard/config-image/mobile" }
                              },
                              [_vm._v("移动端")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("a-layout-content", [_c("router-view")], 1)
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }